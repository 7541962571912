import React from 'react';
import { Layout } from 'antd';
import styles from './LoginLayout.module.less';

interface Props {
  children?: React.ReactNode;
}

const LoginLayout = ({ children }: Props) => {
  return (
    <Layout className={styles.container}>
      <Layout.Content className={styles.content}>{children}</Layout.Content>
    </Layout>
  );
};

export default LoginLayout;
