import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Card, List, PageHeader, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import Content from '../components/layout/Content';
import Page from '../components/layout/Page';
import request from '../helper/request';
import { Booking as ItemInterface } from '../interfaces/booking';

const Bookings = () => {
  const navigate = useNavigate();

  const [items, setItems] = useState<ItemInterface[]>([]);
  const [page, setPage] = useState<number>(1);
  const [numberOfItems, setNumberOfItems] = useState<number>(0);

  useEffect(() => {
    (async () => {
      const data = await request('GET', `admin/bookings?page=${page}&sort[createdAt]=desc`);
      setItems(data.items);
      setNumberOfItems(data.numberOfItems);
    })();
  }, [page]);

  return (
    <Page>
      <PageHeader title="Buchungen" />
      <Content>
        <Card>
          <List
            pagination={{
              current: page,
              total: numberOfItems,
              onChange: (p) => setPage(p),
              defaultPageSize: 20,
              pageSizeOptions: [20],
            }}
            itemLayout="horizontal"
            dataSource={items}
            renderItem={(item: ItemInterface, index) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <a onClick={() => navigate(`/bookings/${item.id}`)}>
                      {item.firstName} {item.lastName}
                    </a>
                  }
                  description={`Gebucht am ${moment(item.createdAt).format(
                    'DD.MM.YYYY, HH:mm',
                  )} Uhr (Status: ${item.state})`}
                />
              </List.Item>
            )}
          />
        </Card>
      </Content>
    </Page>
  );
};

export default Bookings;
