import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Card, List, PageHeader, Space } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import Content from '../components/layout/Content';
import Page from '../components/layout/Page';
import request from '../helper/request';
import { BoxLog as ItemInterface } from '../interfaces/boxLog';

const BoxLog = () => {
  const navigate = useNavigate();

  const [items, setItems] = useState<ItemInterface[]>([]);
  const [page, setPage] = useState<number>(1);
  const [numberOfItems, setNumberOfItems] = useState<number>(0);

  useEffect(() => {
    (async () => {
      const data = await request('GET', `admin/boxes/logs?page=${page}&sort[date]=desc`);
      setItems(data.items);
      setNumberOfItems(data.numberOfItems);
    })();
  }, [page]);

  return (
    <Page>
      <PageHeader title="Protokoll" />
      <Content>
        <Card>
          <List
            pagination={{
              current: page,
              total: numberOfItems,
              onChange: (p) => setPage(p),
              defaultPageSize: 20,
              pageSizeOptions: [20],
            }}
            itemLayout="horizontal"
            dataSource={items}
            renderItem={(item: ItemInterface, index) => (
              <List.Item>
                <List.Item.Meta
                  title={`Box ID ${item.locationId}/${item.boxId}: ${item.action}`}
                  description={
                    <Space>
                      <Space>
                        <span>Datum:</span>
                        <span>{moment(item.createdAt).format('DD.MM.YYYY, HH:mm')} Uhr</span>
                      </Space>
                    </Space>
                  }
                />
              </List.Item>
            )}
          />
        </Card>
      </Content>
    </Page>
  );
};

export default BoxLog;
