import React, { ReactElement } from 'react';
import { Drawer, Layout } from 'antd';
import styles from './Sidebar.module.less';

interface Props {
  menuType: MenuType;
  children: ReactElement;
  collapsed: boolean;
  onCollapse: (collapsed: boolean) => void;
}

export enum MenuType {
  desktop,
  tablet,
  mobile,
}

const Sidebar = ({ children, collapsed, onCollapse, menuType }: Props) => {
  const collapsible = menuType === MenuType.tablet;

  const sider = (
    <Layout.Sider
      collapsible={collapsible}
      width={220}
      className={styles.sidebar}
      collapsed={collapsible && collapsed}
      onCollapse={onCollapse}
    >
      {children}
    </Layout.Sider>
  );

  if (menuType === MenuType.mobile) {
    return (
      <Drawer
        width={220}
        placement="left"
        visible={!collapsed}
        onClose={() => onCollapse(true)}
        closable={false}
        bodyStyle={{
          padding: 0,
        }}
      >
        {sider}
      </Drawer>
    );
  }

  return sider;
};

export default Sidebar;
