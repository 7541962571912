import React from 'react';
import { Layout } from 'antd';
import styles from './Content.module.less';

interface Props {
  children: React.ReactNode;
}

const Content = ({ children }: Props) => {
  return <Layout.Content className={styles.container}>{children}</Layout.Content>;
};

export default Content;
