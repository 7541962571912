import { Modal } from 'antd';
import { LegacyButtonType } from 'antd/es/button/button';

interface Props {
  title: string;
  content: string | JSX.Element;
  okText: string;
  cancelText?: string;
  okType?: LegacyButtonType;
  type?: 'info' | 'success' | 'error' | 'warn' | 'warning' | 'confirm';
  width?: number;
}

export default ({ title, content, okText, cancelText, okType, type, width }: Props) => {
  return new Promise((resolve) => {
    Modal.confirm({
      title,
      content,
      okText,
      cancelText,
      okType,
      type,
      width,
      cancelButtonProps: cancelText
        ? undefined
        : {
            hidden: true,
          },
      onOk: () => {
        resolve(true);
      },
      onCancel: () => {
        resolve(false);
      },
    });
  });
};
