import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Card, PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import Content from '../components/layout/Content';
import Page from '../components/layout/Page';
import request from '../helper/request';
import { Booking as ItemInterface } from '../interfaces/booking';
import Loading from '../components/base/Loading';
import formatAmount from '../helper/formatAmount';
import confirmModal from '../helper/confirmModal';

const Booking = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [booking, setBooking] = useState<ItemInterface>();

  useEffect(() => {
    (async () => {
      const data = await request('GET', `admin/bookings/${params.id}`);
      setBooking(data);
    })();
  }, [params.id]);

  if (!booking) return <Loading />;

  const handleCancel = async () => {
    const result = await confirmModal({
      title: 'Buchung stornieren',
      content: 'Sicher, dass die Buchung storniert werden soll?',
      okText: 'Stornieren',
      cancelText: 'Abbrechen',
    });
    if (result) {
      // Open box
      await request('PATCH', `admin/bookings/${booking.id}/cancel`);
      window.location.reload();
    }
  };

  const handleComplete = async () => {
    const result = await confirmModal({
      title: 'Miete beenden',
      content: 'Sicher, dass die Miete beendet werden soll?',
      okText: 'Beenden',
      cancelText: 'Abbrechen',
    });
    if (result) {
      // Open box
      await request('PATCH', `admin/bookings/${booking.id}/complete`);
      window.location.reload();
    }
  };

  return (
    <Page>
      <PageHeader
        title="Buchung"
        onBack={() => navigate('/bookings')}
        extra={
          ['ACTIVE', 'CREATED', 'OVERDUE'].includes(booking.state) && booking.completedAt === null
            ? [
                booking.state !== 'CREATED' ? (
                  <Button key="block" danger onClick={handleComplete}>
                    Miete beenden
                  </Button>
                ) : undefined,
                <Button key="block" danger onClick={handleCancel}>
                  Buchung stornieren
                </Button>,
              ]
            : []
        }
      />
      <Content>
        <Card>
          <table
            style={{
              textAlign: 'left',
            }}
          >
            <tr>
              <td colSpan={2}>
                <h2>Kunde</h2>
              </td>
            </tr>

            <tr>
              <th
                style={{
                  minWidth: '30%',
                }}
              >
                Vorname:
              </th>
              <td>{booking.firstName}</td>
            </tr>
            <tr>
              <th>Nachname:</th>
              <td>{booking.lastName}</td>
            </tr>
            <tr>
              <th>E-Mail:</th>
              <td>{booking.email}</td>
            </tr>
            <tr>
              <th>Mobilnummer:</th>
              <td>
                {booking.mobile && booking.mobile.trim() !== ''
                  ? booking.mobile
                  : booking.phoneNumber}
              </td>
            </tr>

            <tr>
              <th>Benachrichtigung über:</th>
              <td>{booking.notificationChannel === 'EMAIL' ? 'E-Mail' : 'SMS'}</td>
            </tr>

            <tr>
              <td colSpan={2}>
                <br />
                <br />
                <h2>Buchung</h2>
              </td>
            </tr>

            <tr>
              <th>Zeitraum:</th>
              <td>
                {moment(booking.startDate).format('DD.MM.YYYY, HH:mm')} Uhr -{' '}
                {moment(booking.endDate).format('DD.MM.YYYY, HH:mm')} Uhr
              </td>
            </tr>
            <tr>
              <th>Status:</th>
              <td>{booking.state}</td>
            </tr>

            {booking.completedAt && (
              <tr>
                <th>Abgeschlossen am:</th>
                <td>{moment(booking.completedAt).format('DD.MM.YYYY, HH:mm')} Uhr</td>
              </tr>
            )}
            <tr>
              <th>SUP Typ:</th>
              <td>{booking.contentType}</td>
            </tr>

            <tr>
              <th>Anzahl gebuchter Boxen:</th>
              <td>{booking.numberOfBoxes}</td>
            </tr>

            <tr>
              <th>Verlängerung:</th>
              <td>
                {booking.extendedTo ? moment(booking.extendedTo).format('DD.MM.YYYY, HH:mm') : '-'}
              </td>
            </tr>

            <tr>
              <th>Station:</th>
              <td>{booking.locationId}</td>
            </tr>

            <tr>
              <td colSpan={2}>
                <br />
                <br />
                <h2>Zugewiesene Boxen</h2>
              </td>
            </tr>

            <tr>
              <td colSpan={2}>
                {booking.boxes.map((box) => (
                  <div key={box.id}>
                    <strong>Box {box.number}:</strong> {box.state === 1 ? 'Offen' : 'Geschlossen'}
                  </div>
                ))}
              </td>
            </tr>

            <tr>
              <td colSpan={2}>
                <br />
                <br />
                <h2>Zahlung</h2>
              </td>
            </tr>

            <tr>
              <th>Zahlungstyp:</th>
              <td>{booking.paymentType}</td>
            </tr>
            <tr>
              <th>Abgebuchter Betrag:</th>
              <td>{booking.chargedAmount !== null ? formatAmount(booking.chargedAmount) : '-'}</td>
            </tr>
            <tr>
              <th>Zahlungskennung:</th>
              <td>{booking.paymentId}</td>
            </tr>
            <tr>
              <th>Zahlungsstatus:</th>
              <td style={{ color: booking.paymentStatus === 'ERROR' ? 'red' : 'black' }}>
                {booking.paymentStatus}
              </td>
            </tr>
          </table>
        </Card>
      </Content>
    </Page>
  );
};

export default Booking;
