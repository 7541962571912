import React, { useCallback } from 'react';
import { Button, Card, Form, message, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import Content from '../components/layout/Content';
import Page from '../components/layout/Page';
import request from '../helper/request';
import LocationForm from '../components/locations/LocationForm';

const AddLocation = () => {
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const handleSave = useCallback(async (fields) => {
    const response = await request('POST', `admin/locations`, {
      name: fields.name,
      address: fields.address,
      coordinates: fields.coordinates,
      key: fields.key,
      description: fields.description,
      timezone: fields.timezone,
      openingAt: fields.openingAt.format('HH:mm'),
      closingAt: fields.closingAt.format('HH:mm'),
      dailyRentalEnabled: fields.dailyRentalEnabled || false,
      dailyRate: parseFloat(fields.dailyRate),
      hourlyRate: parseFloat(fields.hourlyRate),
    });

    if (response.error) {
      message.error('Fehler beim Speichern der Änderungen!');
    } else {
      message.success('Standort wurde angelegt');

      navigate('/');
    }
  }, []);

  return (
    <Page>
      <PageHeader title="Standort anlegen" />
      <Content>
        <Card>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSave}
            initialValues={{
              timezone: 'Europe/Berlin',
            }}
          >
            <LocationForm />

            <Button htmlType="submit">Anlegen</Button>
          </Form>
        </Card>
      </Content>
    </Page>
  );
};

export default AddLocation;
