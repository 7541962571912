import React, { useEffect, useMemo, useState } from 'react';
import { AuthContext, LoginResult } from './authContext';
import {
  deleteTokensFromStorage,
  getTokensFromStorage,
  setTokensInStorage,
  tokenIsValid,
} from './tokenHelper';
import request from '../helper/request';

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [token, setToken] = useState<string | null | undefined>(undefined);

  const updateTokens = (t: string) => {
    setTokensInStorage(t);
    setToken(t);
  };

  useEffect(() => {
    (async () => {
      const [loadedToken] = await getTokensFromStorage();

      if (loadedToken && tokenIsValid(loadedToken)) {
        setToken(loadedToken);
      } else {
        setToken(null);
      }
    })();
  }, []);

  const providerValue = useMemo(() => {
    const getAuthToken = async (): Promise<string> => {
      if (token && tokenIsValid(token)) return token;

      // Invalid token
      setToken(null);

      return '';
    };

    const signIn = async (username: string, password: string): Promise<LoginResult> => {
      const result = await request('POST', 'auth/admin/login', {
        username,
        password,
      });

      if (result || result.token) {
        updateTokens(result.token);
        return LoginResult.successful;
      }

      setToken(null);
      return LoginResult.failed;
    };

    const logout = () => {
      deleteTokensFromStorage();
      setToken(null);
    };

    return {
      isAuthenticated: !!token,
      authIsLoading: token === undefined,
      getAuthToken,
      signIn,
      logout,
    };
  }, [token]);

  return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
