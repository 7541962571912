import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/layout/Layout';
import useAuth from './auth/useAuth';
import Loading from './components/base/Loading';
import Login from './pages/Login';
import Locations from './pages/Locations';
import Location from './pages/Location';
import Bookings from './pages/Bookings';
import Booking from './pages/Booking';
import BoxLog from './pages/BoxLog';
import EditLocation from './pages/EditLocation';
import AddLocation from './pages/AddLocation';
import EditLocationBoxes from './pages/EditLocationBoxes';
import Export from './pages/Export';

function App() {
  const { authIsLoading, isAuthenticated } = useAuth();

  if (authIsLoading) return <Loading />;

  return (
    <Routes>
      {isAuthenticated ? (
        <Route element={<Layout />}>
          <Route element={<Locations />} path="" />
          <Route element={<AddLocation />} path="addLocation" />
          <Route element={<EditLocation />} path="locations/:id/edit" />
          <Route element={<EditLocationBoxes />} path="locations/:id/boxes" />
          <Route element={<Location />} path="locations/:id" />
          <Route element={<Booking />} path="bookings/:id" />
          <Route element={<Bookings />} path="bookings" />
          <Route element={<Export />} path="export" />
          <Route element={<BoxLog />} path="boxLog" />
        </Route>
      ) : (
        <Route element={<Login />} path="*" />
      )}
    </Routes>
  );
}

export default App;
