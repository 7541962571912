import React, { useEffect, useState } from 'react';
import { Button, Card, List, PageHeader, Space } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { faDoorOpen, faDoorClosed, faBolt, faBoneBreak } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Content from '../components/layout/Content';
import Page from '../components/layout/Page';
import request from '../helper/request';
import { Location as ItemInterface, Location as LocationInterface } from '../interfaces/location';
import Loading from '../components/base/Loading';
import { Box } from '../interfaces/box';
import confirmModal from '../helper/confirmModal';

const Location = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [location, setLoaction] = useState<LocationInterface>();
  const [boxes, setBoxes] = useState<Box[]>();

  useEffect(() => {
    const id = parseInt(params.id as string, 10);
    (async () => {
      const locations = await request('GET', `admin/locations`);
      setLoaction(locations.find((loc: LocationInterface) => loc.id === id));

      setBoxes(await request('GET', `admin/locations/${id}/boxes`));
    })();
  }, [params.id]);

  const handleOpenBox = (boxNumber: number) => {
    return async () => {
      const result = await confirmModal({
        title: `Box ${boxNumber} öffnen`,
        content: `Sicher, dass Box ${boxNumber} geöffnet werden soll?`,
        okText: 'Öffnen',
        cancelText: 'Abbrechen',
      });
      if (result) {
        // Open box
        const response = await request(
          'PATCH',
          `admin/locations/${location?.id}/boxes/${boxNumber}/open`,
        );
        if (location?.lockType === 'IGLOO') {
          confirmModal({
            title: 'PIN Code',
            content: (
              <Space direction="vertical">
                <div>
                  <strong>Kunden-Code: </strong>{' '}
                  {response.pinCode.split('').map((c: any, i: number) => (
                    <span key={i as any}>
                      {c}
                      {i % 3 === 2 && i !== response.pinCode.length - 1 && ' '}
                    </span>
                  ))}
                </div>

                <div>
                  <strong>Einmalcode 1: </strong>{' '}
                  {response.oneTimePinCode.split('').map((c: any, i: number) => (
                    <span key={i as any}>
                      {c}
                      {i % 3 === 2 && i !== response.oneTimePinCode.length - 1 && ' '}
                    </span>
                  ))}
                </div>
                <div>
                  <strong>Einmalcode 2: </strong>{' '}
                  {response.oneTimePinCode2.split('').map((c: any, i: number) => (
                    <span key={i as any}>
                      {c}
                      {i % 3 === 2 && i !== response.oneTimePinCode2.length - 1 && ' '}
                    </span>
                  ))}
                </div>
                <div>
                  <strong>Einmalcode 3: </strong>{' '}
                  {response.oneTimePinCode3.split('').map((c: any, i: number) => (
                    <span key={i as any}>
                      {c}
                      {i % 3 === 2 && i !== response.oneTimePinCode3.length - 1 && ' '}
                    </span>
                  ))}
                </div>
              </Space>
            ),
            okText: 'Schließen',
            type: 'info',
          });
        } else {
          window.location.reload();
        }
      }
    };
  };

  const handleBlockBox = (boxNumber: number, blocked: boolean) => {
    return async () => {
      const result = await confirmModal({
        title: `Box ${boxNumber} ${blocked ? 'sperren' : 'entsperren'}`,
        content: `Sicher, dass Box ${boxNumber} ${blocked ? 'gesperrt' : 'entsperrt'} werden soll?`,
        okText: blocked ? 'Sperren' : 'Entsperren',
        cancelText: 'Abbrechen',
      });
      if (result) {
        // Open box
        await request('PATCH', `admin/locations/${location?.id}/boxes/${boxNumber}`, {
          blocked,
        });
        window.location.reload();
      }
    };
  };

  if (!location) return <Loading />;

  return (
    <Page>
      <PageHeader
        title={location.name}
        extra={[
          <Space key="space">
            <Button
              key="edit"
              type="default"
              onClick={() => navigate(`/locations/${location?.id}/edit`)}
            >
              Standort bearbeiten
            </Button>
            <Button
              key="edit"
              type="default"
              onClick={() => navigate(`/locations/${location?.id}/boxes`)}
            >
              Boxen bearbeiten
            </Button>
          </Space>,
        ]}
      />
      <Content>
        <Card>
          <List
            itemLayout="horizontal"
            dataSource={boxes}
            renderItem={(box: Box, index) => (
              <List.Item>
                <List.Item.Meta
                  title={`Box ${box.number}`}
                  description={
                    <Space>
                      {location?.lockType !== 'IGLOO' && (
                        <div>
                          {box.state === 0 ? (
                            <Space>
                              <FontAwesomeIcon icon={faDoorClosed} />
                              Geschlossen
                            </Space>
                          ) : (
                            <Space>
                              <FontAwesomeIcon icon={faDoorOpen} />
                              Offen
                            </Space>
                          )}
                        </div>
                      )}
                      {box.blocked && (
                        <Space>
                          <FontAwesomeIcon icon={faBolt} />
                          Gesperrt
                        </Space>
                      )}
                      {!box.usable && (
                        <Space>
                          <FontAwesomeIcon icon={faBoneBreak} />
                          Defekt
                        </Space>
                      )}
                    </Space>
                  }
                />

                <Space
                  style={{
                    display: 'flex',
                    position: 'absolute',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                >
                  {!box.blocked && (
                    <Button danger onClick={handleBlockBox(box.number, true)}>
                      Sperren
                    </Button>
                  )}
                  {box.blocked && (
                    <Button danger onClick={handleBlockBox(box.number, false)}>
                      Entsperren
                    </Button>
                  )}
                  {box.state === 0 && <Button onClick={handleOpenBox(box.number)}>Öffnen</Button>}
                  {box.state === 1 && <Button disabled>Öffnen</Button>}
                </Space>
              </List.Item>
            )}
            locale={{
              emptyText: <div>Es wurden noch keine Boxen angelegt</div>,
            }}
          />
        </Card>
      </Content>
    </Page>
  );
};

export default Location;
