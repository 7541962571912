import { Col, Form, Input, Row, Select, Switch, TimePicker } from 'antd';
import React from 'react';
import { Moment } from 'moment';

export interface FormFields {
  name: string;
  address: {
    street: string;
    zipcode: string;
    city: string;
  };
  coordinates: {
    lat: number;
    lng: number;
  };
  key: string;
  description: string;
  timezone: string;
  openingAt: Moment;
  closingAt: Moment;
  dailyRentalEnabled: boolean;
  dailyRate: number;
  hourlyRate: number;
  minHours: number;
  lockType: string;
}

const LocationForm = () => {
  return (
    <Row gutter={24}>
      <Col span={24}>
        <Form.Item name="name" label="Name">
          <Input />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name={['address', 'street']} label="Straße">
          <Input />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name={['address', 'zipcode']} label="PLZ">
          <Input />
        </Form.Item>
      </Col>
      <Col span={18}>
        <Form.Item name={['address', 'city']} label="Ort">
          <Input />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item name="key" label="Eindeutiger Schlüssel (IGLOO Home Name)">
          <Input />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item name="description" label="Beschreibung">
          <Input.TextArea />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item name="timezone" label="Zeitzone">
          <Input />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="openingAt" label="Öffnet um">
          <TimePicker />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="closingAt" label="Schließt um">
          <TimePicker />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item name="dailyRentalEnabled" label="Tagesmiete erlauben" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="dailyRate" label="Preis pro Tag">
          <Input />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="hourlyRate" label="Preis pro Stunde">
          <Input />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="minHours" label="Mindestdauer in Stunden">
          <Input />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="lockType" label="Schlosstyp">
          <Select
            options={[
              {
                label: 'igloohome',
                value: 'IGLOO',
              },
              {
                label: 'Ansteuerung über Internet',
                value: 'MQTT',
              },
            ]}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default LocationForm;
