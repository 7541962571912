import moment from 'moment';
import { Buffer } from 'buffer';

export const getTokenExpiryDate = (t: string) => {
  // Decode JWT
  const tokenParts = t.split('.');
  if (!tokenParts || tokenParts.length !== 3) return moment('1970-01-01');

  // Check expiry date
  const tokenData: { exp: number } = JSON.parse(Buffer.from(tokenParts[1], 'base64').toString());

  return moment.unix(tokenData.exp);
};

export const getTokenGroup = (t: string) => {
  // Decode JWT
  const tokenParts = t.split('.');
  if (!tokenParts || tokenParts.length !== 3) return null;

  // Check expiry date
  const tokenData: { group: string } = JSON.parse(Buffer.from(tokenParts[1], 'base64').toString());

  return tokenData.group;
};

export const tokenIsValid = (t: string) => {
  if (!t) return false;

  return getTokenGroup(t) === 'admin';
};

export const getTokensFromStorage = async () => {
  const loadedToken = window.localStorage.getItem('token');

  return [loadedToken];
};

export const setTokensInStorage = (t: string) => {
  window.localStorage.setItem('token', t);
};

export const deleteTokensFromStorage = () => {
  window.localStorage.removeItem('token');
};

export const getUserIdFromToken = (t: string) => {
  // Decode JWT
  const tokenParts = t.split('.');
  if (!tokenParts || tokenParts.length !== 3) return 0;

  // Check expiry date
  const tokenData: { id: unknown } = JSON.parse(Buffer.from(tokenParts[1], 'base64').toString());

  return tokenData.id;
};
