import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Form, message, PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import Content from '../components/layout/Content';
import Page from '../components/layout/Page';
import request from '../helper/request';
import { Location as LocationInterface } from '../interfaces/location';
import Loading from '../components/base/Loading';
import { Box } from '../interfaces/box';
import LocationForm, { FormFields } from '../components/locations/LocationForm';

const EditLocation = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [location, setLoaction] = useState<LocationInterface>();
  const [boxes, setBoxes] = useState<Box[]>();

  const [form] = Form.useForm<FormFields>();

  useEffect(() => {
    const id = parseInt(params.id as string, 10);
    (async () => {
      const locations = await request('GET', `admin/locations`);
      const l = locations.find((loc: LocationInterface) => loc.id === id);

      setLoaction(l);

      form.setFieldsValue({
        name: l.name,
        address: l.address,
        coordinates: l.coordinates,
        key: l.key,
        description: l.description,
        timezone: l.timezone,
        openingAt: moment(`2023-08-01 ${l.openingAt}`),
        closingAt: moment(`2023-08-01 ${l.closingAt}`),
        dailyRentalEnabled: l.dailyRentalEnabled,
        dailyRate: l.dailyRate,
        hourlyRate: l.hourlyRate,
        minHours: l.minHours || 1,
        lockType: l.lockType,
      });

      setBoxes(await request('GET', `admin/locations/${id}/boxes`));
    })();
  }, [params.id]);

  const handleSave = useCallback(
    async (fields) => {
      const response = await request('PATCH', `admin/locations/${location?.id}`, {
        name: fields.name,
        address: fields.address,
        coordinates: fields.coordinates,
        key: fields.key,
        description: fields.description,
        timezone: fields.timezone,
        lockType: fields.lockType,
        openingAt: fields.openingAt.format('HH:mm'),
        closingAt: fields.closingAt.format('HH:mm'),
        dailyRentalEnabled: fields.dailyRentalEnabled || false,
        dailyRate: parseFloat(fields.dailyRate),
        hourlyRate: parseFloat(fields.hourlyRate),
        minHours: parseFloat(fields.minHours),
      });

      if (response.error) {
        message.error('Fehler beim Speichern der Änderungen!');
      } else {
        message.success('Änderungen wurden gespeichert');
      }
    },
    [location],
  );

  if (!location) return <Loading />;

  return (
    <Page>
      <PageHeader title={`${location.name} bearbeiten`} />
      <Content>
        <Card>
          <Form form={form} layout="vertical" onFinish={handleSave}>
            <LocationForm />

            <Button htmlType="submit">Speichern</Button>
          </Form>
        </Card>
      </Content>
    </Page>
  );
};

export default EditLocation;
