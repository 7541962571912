import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import locale from 'antd/es/locale/de_DE';
import 'antd/dist/antd.css';
import App from './App';
import AuthProvider from './auth/authProvider';

ConfigProvider.config({
  theme: {
    primaryColor: '#88AEB3',
  },
});

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <ConfigProvider locale={locale}>
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
