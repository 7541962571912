import React, { useEffect, useState } from 'react';
import { Layout as BaseLayout } from 'antd';
import { Outlet } from 'react-router-dom';
import styles from './Layout.module.less';
import Navigation from './Navigation';
import Sidebar, { MenuType } from './Sidebar';
import Header from './Header';

const Layout = () => {
  const [menuCollapsed, setMenuCollapsed] = useState<boolean>(true);
  const [menuType, setMenuType] = useState<MenuType>(MenuType.desktop);

  const handleWindowResize = () => {
    if (window.innerWidth < 768) {
      setMenuType(MenuType.mobile);
    } else if (window.innerWidth < 1024) {
      setMenuType(MenuType.tablet);
    } else {
      setMenuType(MenuType.desktop);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize, false);
    handleWindowResize();
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <BaseLayout className={styles.container}>
      <Header menuCollapsed={menuCollapsed} onMenuCollapse={setMenuCollapsed} menuType={menuType} />
      <BaseLayout>
        <Sidebar collapsed={menuCollapsed} onCollapse={setMenuCollapsed} menuType={menuType}>
          <Navigation onSelect={() => setMenuCollapsed(true)} />
        </Sidebar>
        <BaseLayout>
          <Outlet />
        </BaseLayout>
      </BaseLayout>
    </BaseLayout>
  );
};

export default Layout;
