import React, { useCallback, useState } from 'react';
import { Button, Card, DatePicker, PageHeader, Space } from 'antd';
import { Moment } from 'moment';
import locale from 'antd/es/date-picker/locale/de_DE';
import { useNavigate } from 'react-router-dom';
import Content from '../components/layout/Content';
import Page from '../components/layout/Page';
import request from '../helper/request';

const Export = () => {
  const navigate = useNavigate();

  const [dateRange, setDateRange] = useState<any>();

  const handleDownload = useCallback(async () => {
    if (!dateRange || !dateRange[0] || !dateRange[1]) return;

    const data = await request(
      'GET',
      `admin/bookings/export?startDate=${dateRange[0].format(
        'YYYY-MM-DD',
      )}&endDate=${dateRange[1].format('YYYY-MM-DD')}`,
    );

    const csvContent = `data:text/csv;charset=utf-8,${data
      .map((e: any) => e.join(';'))
      .join('\n')}`;

    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }, [dateRange]);

  return (
    <Page>
      <PageHeader title="Export" />
      <Content>
        <Card>
          <Space>
            <DatePicker.RangePicker
              onChange={setDateRange}
              value={dateRange}
              locale={locale}
              format="DD.MM.YYYY"
            />
            <Button type="primary" onClick={handleDownload}>
              Herunterladen
            </Button>
          </Space>
        </Card>
      </Content>
    </Page>
  );
};

export default Export;
