import React from 'react';
import styles from './Logo.module.less';

interface Props {
  styling?: 'light' | 'dark' | 'primary';
}

const Logo = ({ styling }: Props) => {
  return (
    <div className={`${styles.logo} ${styling === 'dark' ? styles.dark : ''}`}>
      SUP DUDES
    </div>
  );
};

export default Logo;
