import React, { useEffect, useState } from 'react';
import { Button, Layout, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faWater, faArrowRightFromBracket } from '@fortawesome/pro-solid-svg-icons';
import styles from './Header.module.less';
import { MenuType } from './Sidebar';
import useAuth from '../../auth/useAuth';
import Logo from './Logo';

interface Props {
  menuType: MenuType;
  menuCollapsed: boolean;
  onMenuCollapse: (collapsed: boolean) => void;
}

const Header = ({ menuCollapsed, onMenuCollapse, menuType }: Props) => {
  const { logout } = useAuth();
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize, false);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <Layout.Header
      className={[styles.header, menuType === MenuType.mobile && styles.mobile]
        .filter((c) => !!c)
        .join(' ')}
    >
      {menuType === MenuType.mobile && (
        <div className={styles.menuButton} onClick={() => onMenuCollapse(false)}>
          <FontAwesomeIcon icon={faBars} size="2x" />
        </div>
      )}
      <Logo />
      <div className={styles.menu}>
        <Button type="link" onClick={logout}>
          <Space>
            <FontAwesomeIcon icon={faArrowRightFromBracket} />
            Abmelden
          </Space>
        </Button>
      </div>
    </Layout.Header>
  );
};

export default Header;
