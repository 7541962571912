import React, { useEffect, useState } from 'react';
import { Button, Card, List, PageHeader, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import Content from '../components/layout/Content';
import Page from '../components/layout/Page';
import request from '../helper/request';
import { Location as ItemInterface } from '../interfaces/location';

const Locations = () => {
  const navigate = useNavigate();

  const [items, setItems] = useState<ItemInterface[]>([]);

  useEffect(() => {
    (async () => {
      const data = await request('GET', 'admin/locations');
      setItems(data);
    })();
  }, []);

  return (
    <Page>
      <PageHeader
        title="Standorte"
        extra={[
          <Button key="add" onClick={() => navigate('/addLocation')}>
            Standort anlegen
          </Button>,
        ]}
      />
      <Content>
        <Card>
          <List
            itemLayout="horizontal"
            dataSource={items}
            renderItem={(item: ItemInterface, index) => (
              <List.Item>
                <List.Item.Meta
                  title={<a onClick={() => navigate(`locations/${item.id}`)}>{item.name}</a>}
                  description={item.address.city}
                />
              </List.Item>
            )}
          />
        </Card>
      </Content>
    </Page>
  );
};

export default Locations;
