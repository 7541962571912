import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Form, Input, List, PageHeader, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBolt,
  faBoneBreak,
  faDoorClosed,
  faDoorOpen,
  faPencil,
} from '@fortawesome/pro-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import Content from '../components/layout/Content';
import Page from '../components/layout/Page';
import request from '../helper/request';
import { Location as LocationInterface } from '../interfaces/location';
import Loading from '../components/base/Loading';
import { Box } from '../interfaces/box';
import confirmModal from '../helper/confirmModal';

const EditLocationBoxes = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [location, setLoaction] = useState<LocationInterface>();
  const [boxes, setBoxes] = useState<Box[]>();

  const [externalIdForm] = Form.useForm();

  useEffect(() => {
    const id = parseInt(params.id as string, 10);
    (async () => {
      const locations = await request('GET', `admin/locations`);
      setLoaction(locations.find((loc: LocationInterface) => loc.id === id));

      setBoxes(await request('GET', `admin/locations/${id}/boxes`));
    })();
  }, [params.id]);

  const handleDelete = useCallback(
    async (boxId: number) => {
      if (
        await confirmModal({
          title: 'Box löschen',
          okText: 'Löschen',
          cancelText: 'Abbrechen',
          content: 'Das Löschen einer Box kann nicht rückgängig gemacht werden!',
        })
      ) {
        await request('DELETE', `admin/locations/${location?.id}/boxes/${boxId}`);
        setBoxes(await request('GET', `admin/locations/${location?.id}/boxes`));
      }
    },
    [location],
  );

  const handleChangeExternalId = useCallback(
    async (boxNumber: number, externalId: string) => {
      externalIdForm.setFieldsValue({
        externalId,
      });

      if (
        await confirmModal({
          title: 'Box bearbeiten',
          okText: 'Speichern',
          cancelText: 'Abbrechen',
          content: (
            <Form form={externalIdForm} layout="vertical">
              <Form.Item name="externalId" label="ID (z.B. 'IGP...')">
                <Input />
              </Form.Item>
            </Form>
          ),
        })
      ) {
        const fields = await externalIdForm.validateFields();

        await request('PATCH', `admin/locations/${location?.id}/boxes/${boxNumber}`, {
          externalId: fields.externalId,
        });
        setBoxes(await request('GET', `admin/locations/${location?.id}/boxes`));
      }
    },
    [location, externalIdForm],
  );

  const handleAdd = useCallback(async () => {
    let number = 0;
    boxes?.forEach((box) => {
      if (box.number > number) number = box.number;
    });

    number += 1;

    await request('POST', `admin/locations/${location?.id}/boxes`, {
      number,
      content: 'INFLATABLE_BOARD',
    });

    setBoxes(await request('GET', `admin/locations/${location?.id}/boxes`));
  }, [location, boxes]);

  if (!location) return <Loading />;

  return (
    <Page>
      <PageHeader
        title={`${location.name} | Fächer bearbeiten`}
        extra={[
          <Button key="add" onClick={handleAdd}>
            Box hinzufügen
          </Button>,
        ]}
      />
      <Content>
        <Card>
          <List
            itemLayout="horizontal"
            dataSource={boxes}
            renderItem={(box: Box, index) => (
              <List.Item>
                <List.Item.Meta
                  title={`Box ${box.number}`}
                  description={
                    <div>
                      {location?.lockType === 'IGLOO' && (
                        <div>
                          <Space>
                            {box.externalId || 'Keine ID vergeben'}
                            <FontAwesomeIcon
                              icon={faPencil}
                              onClick={() => {
                                handleChangeExternalId(box.number, box.externalId);
                              }}
                              style={{
                                cursor: 'pointer',
                              }}
                            />
                          </Space>
                        </div>
                      )}
                    </div>
                  }
                />

                <Button
                  danger
                  onClick={() => {
                    handleDelete(box.id);
                  }}
                >
                  Löschen
                </Button>
              </List.Item>
            )}
            locale={{
              emptyText: <div>Es wurden noch keine Boxen angelegt</div>,
            }}
          />
        </Card>
      </Content>
    </Page>
  );
};

export default EditLocationBoxes;
