import React, { useState } from 'react';
import { Button, Card, Input, Space } from 'antd';
import useAuth from '../auth/useAuth';
import LoginLayout from '../components/layout/LoginLayout';

const Login = () => {
  const { signIn } = useAuth();

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleLogin = async () => {
    await signIn(username, password);
  };

  return (
    <LoginLayout>
      <Card>
        <Space
          direction="vertical"
          style={{
            width: '100%',
          }}
        >
          <Input
            placeholder="E-Mail Adresse"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Input.Password
            placeholder="Passwort"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <Button type="primary" onClick={handleLogin}>
            Anmelden
          </Button>
        </Space>
      </Card>
    </LoginLayout>
  );
};

export default Login;
