import React from 'react';
import { Menu } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconDefinition,
  faDashboard,
  faUsers,
  faCalendar,
  faWater,
  faTicket,
  faShoppingCart,
  faLocation,
  faUserAstronaut,
  faBox,
  faTable,
  faFileExport,
} from '@fortawesome/pro-solid-svg-icons';

interface Route {
  label: string;
  path: string;
  exactPath?: boolean;
  icon: IconDefinition;
  childrenTitle?: string;
  children?: Route[];
}

interface Props {
  onSelect?: () => void;
}

const Navigation = ({ onSelect }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const routes: Route[] = [
    {
      label: 'Standorte',
      path: '/',
      icon: faUsers,
      exactPath: true,
    },
    {
      label: 'Buchungen',
      path: '/bookings',
      icon: faTable,
      exactPath: true,
    },
    {
      label: 'Protokoll',
      path: '/boxLog',
      icon: faTable,
      exactPath: true,
    },
    {
      label: 'Export',
      path: '/export',
      icon: faFileExport,
      exactPath: true,
    },
  ];

  const handleSelect = (route: string) => {
    return () => {
      navigate(route);
      onSelect?.();
    };
  };

  const getSelectedKeys = () => {
    const selectedKeys: string[] = [];
    routes.forEach((route) => {
      if (
        (!route.exactPath && location.pathname.indexOf(route.path) === 0) ||
        (route.exactPath && location.pathname === route.path)
      ) {
        selectedKeys.push(route.path);
      }
    });
    return selectedKeys;
  };

  return (
    <Menu mode="inline" selectedKeys={getSelectedKeys()}>
      {routes.map((route) =>
        !route.children ? (
          <Menu.Item
            key={route.path}
            onClick={handleSelect(route.path)}
            icon={<FontAwesomeIcon icon={route.icon} />}
          >
            {route.label}
          </Menu.Item>
        ) : (
          <Menu.SubMenu
            key={route.path}
            title={route.label}
            icon={<FontAwesomeIcon icon={route.icon} />}
          >
            <Menu.ItemGroup key="g1" title={route.childrenTitle}>
              {route.children.map((child) => (
                <Menu.Item
                  key={`${route.path}-${child.path}`}
                  icon={<FontAwesomeIcon icon={child.icon} />}
                  onClick={handleSelect(child.path)}
                >
                  {child.label}
                </Menu.Item>
              ))}
            </Menu.ItemGroup>
          </Menu.SubMenu>
        ),
      )}
    </Menu>
  );
};

export default Navigation;
