const apiURL = (window as { apiURL?: string }).apiURL || process.env.REACT_APP_SERVER_URI;

const request = async (
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE',
  path: string,
  data?: object,
) => {
  const token = window.localStorage.getItem('token');

  const result = await fetch(`${apiURL}/${path}`, {
    method,
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
  });
  return result.json();
};

export default request;
